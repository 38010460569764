<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group id="input-group-1" label-for="input-1" class="col-4">
                <label style="font-weight: 500">Name <span style="color: red">*</span></label>
                <b-form-input id="input-1" placeholder="Enter Name" v-model="form.name" :class="{
                  'is-invalid': submitted && $v.form.name.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-2" label-for="input-2" class="col-4">
                <label style="font-weight: 500">Subject</label>
                <b-form-input id="input-2" placeholder="Enter Subject" v-model="form.subject"></b-form-input>
              </b-form-group>
              <b-form-group label-for="in-forum" class="col-4">
              <label style="font-weight: 500">Forum</label>
                <multiselect @search-change="fetchForum" id="in-forum" v-model="form.brand_forum_id" :options="getForum"
                  :multiple="false" track-by="id" label="title" placeholder="Type here to search">
                  <span slot="noOptions">
                    Type here to search
                  </span>
                </multiselect>
              </b-form-group>
              <div class="col-12">
                <div class="d-flex justify-content-start align-items-center mb-2">
                  <label class="mb-0" style="font-weight: 500">Send Email HTML Content</label>
                  <span class="badge badge-primary ml-3 p-1" style="cursor: pointer" @click="formatCode" v-if="form.content">Format Code</span>
                </div>
                <ace-editor v-model="form.content" @init="editorInit" lang="html" theme="chrome" width="100%" height="1000" class="mb-3"></ace-editor>
              </div>
              <div class="col-12 mb-3" v-if="form.content">
                <b-button class="btn btn-info btn-sm" @click="showPreview">
                  <span v-if="previewEmail">Close Preview</span>
                  <span v-else>Preview Email HTML</span>
                </b-button>
                <div v-if="previewEmail && form.content">
                  <label class="my-3 d-block text-center">Preview of HTML Content</label>
                  <div v-html="form.content" class="d-flex align-items-center"></div>
                </div>
              </div>
              <div class="col-12">
                <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                    v-if="this.$route.name == 'add-email-notification'">Save Data</span>
                  <span v-else>Update Data</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import notificationEmail from "../../../../mixins/ModuleJs/notification-email";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, notificationEmail],
  data() {
    return {
      submitted: false,
      title: "Email",
      items: [
        {
          text: "Back",
          href: "/email-notification",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required },
      content: { required },
    },
  },
};
</script>
